.main-dashboard{
    width: 100%;
    background-color: #F7F8FC;
}

.partner-balance{
    padding-top: 0.5rem!important;
    padding-bottom: 0.5rem!important;
    padding-left: 20px;
}

.small-balance-info{
    color: var(--gray-2, #8A8DA0);
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
}

.big-balance-info{
    color: var(--white, #FFF);
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px; 
}