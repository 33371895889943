.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.select2-hidden-accessible {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  height: 1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
  white-space: nowrap !important;
}

.select2-container {
  box-sizing: border-box;
  display: inline-block;
  margin: 0;
  position: relative;
  vertical-align: middle;
}


.containerfl {
  display: flex;
  justify-content: space-between; /* Jarak antar kolom */
}

/* Mengatur lebar dan tinggi kolom */
.columnfl {
  width: calc(12.50% - 10px); /* 16.6667% untuk 6 kolom, dikurangi 30px jarak */
  height: 50px;
  float:left;
  padding-left: 5px;
  /* background-color: #3498db; */
  /* border: solid red 1px; */

}

.mm{
  width:280px;
  /* border:solid blue 4px; */
}

.sp30{
  margin-left: 30px;
}

