.FlexContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: flex-start;
    width: 95%;
    font-family: 'Poppins';
    /* border:solid red 1px; */
    /* background-color: #2f1d58; */
    /* height: 80vh; */
    overflow: auto;
    flex-direction: row;
}

.FlexContainer .div40 {
    width: 40%;
    height: 40%;
    /* margin: 5px; */
    /* background-color: lightblue; */
    min-height: 80px;
    height: auto;
    vertical-align: middle;
}

.FlexContainer .div30 {
    width: 30%;
    height: 30%;
    /* margin: 5px; */
    /* background-color: lightblue; */
    min-height: 80px;
    height: auto;
    vertical-align: middle;
}

.FlexContainer .div15 {
    width: 15%;
    height: 15%;
    /* margin: 5px; */
    /* background-color: lightblue; */
    min-height: 80px;
    height: auto;
    vertical-align: middle;
}




table.blackTable {
    font-family: 'Poppins';
    width: 100%;
    text-align: left;
    border-collapse: collapse;
  }
  table.blackTable td, table.blackTable th {
    border-bottom: 1px solid #d2d2d2;
    padding: 12px 8px;
  }
  table.blackTable tbody td {
    font-size: 14px;
  }
  table.blackTable thead {
    background: #000000;
    background: -moz-linear-gradient(top, #404040 0%, #191919 66%, #000000 100%);
    background: -webkit-linear-gradient(top, #404040 0%, #191919 66%, #000000 100%);
    background: linear-gradient(to bottom, #404040 0%, #191919 66%, #000000 100%);
  }
  table.blackTable thead th {
    font-size: 14px;
    font-weight: bold;
    color: #FFFFFF;
    text-align: left;
  }
  table.blackTable tfoot td {
    font-size: 14px;
  }
  table.blackTable tfoot .links {
    text-align: right;
  }
  table.blackTable tfoot .links a{
    display: inline-block;
    background: #1C6EA4;
    color: #FFFFFF;
    padding: 2px 8px;
    border-radius: 5px;
  }





.control {
    display: block;
    position: relative;
    padding-left: 30px;
    margin-bottom: 3px;
    padding-top: 3px;
    cursor: pointer;
    font-size: 15px;
}
.control input {
    position: absolute;
    z-index: -1;
    opacity: 0;
}
.control_indicator {
    position: absolute;
    top: -5px;
    left: 0;
    height: 19px;
    width: 20px;
    background: #e6e6e6;
    border: 0px solid #000000;
    border-radius: 0px;
}
.control:hover input ~ .control_indicator,
.control input:focus ~ .control_indicator {
    background: #cccccc;
}

.control input:checked ~ .control_indicator {
    background: #2aa1c0;
}
.control:hover input:not([disabled]):checked ~ .control_indicator,
.control input:checked:focus ~ .control_indicator {
    background: '#0e6647d';
}
.control input:disabled ~ .control_indicator {
    background: #e6e6e6;
    opacity: 0.6;
    pointer-events: none;
}
.control_indicator:after {
    box-sizing: unset;
    content: '';
    position: absolute;
    display: none;
}
.control input:checked ~ .control_indicator:after {
    display: block;
}
.control-checkbox .control_indicator:after {
    left: 6px;
    top: 1px;
    width: 5px;
    height: 12px;
    border: solid #ffffff;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}
.control-checkbox input:disabled ~ .control_indicator:after {
    border-color: #7b7b7b;
}
.control-checkbox .control_indicator::before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 4.251rem;
    height: 4.5rem;
    margin-left: -1.3rem;
    margin-top: -1.3rem;
    background: #2aa1c0;
    border-radius: 3rem;
    opacity: 0.5;
    z-index: 99999;
    transform: scale(0);
}
@keyframes s-ripple {
    0% {
        transform: scale(0);
    }
    20% {
        transform: scale(1);
    }
    100% {
        opacity: 0;
        transform: scale(1);
    }
}
@keyframes s-ripple-dup {
   0% {
       transform: scale(0);
    }
   30% {
        transform: scale(1);
    }
    60% {
        transform: scale(1);
    }
    100% {
        opacity: 0;
        transform: scale(1);
    }
}
.control-checkbox input + .control_indicator::before {
    animation: s-ripple 250ms ease-out;
}
.control-checkbox input:checked + .control_indicator::before {
    animation-name: s-ripple-dup;
}