.tbody{
    font-size: 12px;
}

.containerMdr {
    display: flex;
    flex-direction: column;
    max-width: 65%;
    padding-left: 40px;
    font-family: 'Poppins';
    font-weight: 400;
    font-size:14px;
  }
  
  .rowMdr {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
  }
  
  .colMdr-0 {
    flex: 1 0 10%; /* flex-grow: 1, flex-shrink: 0, max-width: 40% */
    padding: 2px;
    /* background-color: #f2f2f2; */
    /* border: 1px solid #ccc; */
  }
  
  .colMdr-1 {
    flex: 1 0 30%; /* flex-grow: 1, flex-shrink: 0, max-width: 40% */
    padding: 2px;
    /* background-color: #f2f2f2; */
    /* border: 1px solid #ccc; */
  }
  
  .colMdr-2 {
    flex: 1 0 55%; /* flex-grow: 1 */
    padding: 2px;
    /* background-color: #f2f2f2; */
    /* border: 1px solid #ccc; */
  }
  
  .colMdr-3 {
    flex: 1 0 5%; /* flex-grow: 1 */
    padding: 2px;
    /* background-color: #f2f2f2; */
    /* border: 1px solid #ccc; */
  }

  .kotak{
    border-radius:20px;
    border:solid #cdcdcd 1px;
    height: auto;
    display: inline;
    width: 100%;
    padding:20px;
    position: relative;
    margin-top:20px;
    float:left;
  }

  .button-row {
    display: inline;
    position: relative;
    float: left;
    justify-content: space-between;
    margin-bottom: 10px;
    margin-top:10px;
  }
  
  .full-width-button {
    flex: 1;
    padding: 10px;
    background-color: #ffffff;
    color: #2F80ED;
    font-weight: 600;
    border: solid 2px #2F80ED;
    cursor: pointer;
    border-radius: 10px;
    width:60%;

  }


  .selectMdr{
    width:80%;
    border: solid 1px #cdcdcd;
    height: 35px;
    border-radius:5px;
    padding-left: 10px;
  }

  .radioMdr{
    height: 25px;
    width: 20px;
  }


  
.chip-mdr {
    display: inline-block;
    background-color: #afb3c5; /* Warna latar belakang */
    color: #ffffff; /* Warna teks */
    padding: 5px 10px; /* Padding untuk menjaga tampilan */
    border-radius: 20px; /* Sudut bulat */
    cursor: pointer; /* Ubah tampilan kursor saat diarahkan ke atas */
    text-align: left;
    border:solid #B3B7CF 1px;
    font-size: 14px;
    margin: 5px;
  }
  
  
  .aktif{
    display: inline-block;
    background-color: #28ba8a; /* Warna latar belakang */
    color: #ffffff; /* Warna teks */
    border:solid #28ba8a 1px;
    font-weight: 600;
  }

  .tebal{
    font-weight: bold;
  }